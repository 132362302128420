.uslugePage .naslovna {
  filter: brightness(0.45);
}

@media (max-width: 1304px) {
  .uslugePage .opisNaSlici {
    top: 11vw;
    width: 78vw;
  }
}
@media (max-width: 750px) {
  .uslugePage .iskopavanje {
    padding-top: 22vw;
  }
}
@media (max-width: 785px) {
  .uslugePage .opisNaSlici {
    left: 3rem;
  }
}
@media (max-width: 563px) {
  .uslugePage .opisNaSlici {
    top: 5rem;
    width: 27rem;
  }
}
@media (max-width: 496px) {
  .uslugePage .opisNaSlici {
    width: 82vw;
  }
  .uslugePage .opisNaSlici p {
    width: 82vw;
  }
}
@media (max-width: 730px) {
}
@media (max-width: 730px) {
}
@media (max-width: 730px) {
}
@media (max-width: 730px) {
}
@media (max-width: 730px) {
}

@media (max-width: 730px) {
  .uslugePage .opisNaSlici {
    top: 7rem;
  }
}
@media (max-width: 645px) {
  .uslugePage .opisNaSlici {
    top: 5rem;
  }
}
@media (max-width: 516px) {
  .uslugePage .opisNaSlici {
    left: 3rem;
  }
  .uslugePage .opisNaSlici h1 {
    font-size: 2rem;
  }
  .uslugePage .opisNaSlici p {
    line-height: 1.2rem;
    font-size: 0.89rem;
  }
  .opisNaSlici {
    top: 22.5vw !important;
  }
}

@media (max-width: 688px) {
  .uslugePage {
  }
}
@media (max-width: 688px) {
  .uslugePage {
  }
}
@media (max-width: 688px) {
  .uslugePage {
  }
}
@media (max-width: 688px) {
  .uslugePage {
  }
}
@media (max-width: 688px) {
  .uslugePage {
  }
}
@media (max-width: 517px) {
  .uslugePage .navbarLink {
    width: 77vw;
  }
}

@media (max-width: 414px) {
  .uslugePage .opisNaSlici p {
    line-height: 1rem;
    font-size: 0.7rem;
    padding-top: 1rem;
  }
}
