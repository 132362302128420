.imgIskop {
  width: 38rem;
  border-radius: 0.5vw;
}
.iskopavanje {
  padding-top: 7vw;
}
.iskop {
  display: flex;
  padding: 0 10vw;
  gap: 3vw;
}
.opisIskop h1 {
  padding: 1vw 0;
}
.oznaceno {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 2vw;
}
.oznaceno p {
  display: flex;
  align-items: center;
}
.opisIskop p {
  line-height: 1.5rem;
  gap: 0.5vw;
  color: #888;
  font-size: 1rem;
}
.oznaceno p img {
  width: 1rem;
}
.postavljanje {
  display: flex;
  padding: 7vw 10vw 7vw 10vw;
  gap: 3vw;
}
.postavljanje h1 {
  padding: 1vw 0;
}

.tekstPostavljanje {
  line-height: 1.5rem;
  gap: 0.5vw;
  color: #888;
}
.oznacenoPostavljanje {
  line-height: 1.5rem;
  gap: 0.5vw;
  color: #888;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 2vw;
}
.tacno {
  width: 1vw;
}
.brDva {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
@media (max-width: 1200px) {
  .iskop {
    flex-direction: column;
  }
  .postavljanje {
    flex-direction: column;
  }
}
@media (max-width: 900px) {
  .imgIskop {
    width: 29rem;
  }
  .opisIskop {
    width: 32rem;
  }
}
@media (max-width: 630px) {
  .imgIskop {
    width: 25rem;
  }
  .opisIskop {
    width: 27rem;
  }
  .opisIskop p {
    line-height: 1.1rem;
    gap: 0.5vw;
    color: #888;
    font-size: 0.8rem;
  }
  .opisIskop h1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 500px) {
  .oznaceno {
    gap: 1vw;
    display: grid;
    grid-template-columns: 1fr;
    padding-top: 2vw;
  }
  .imgIskop {
    width: 21rem;
  }
  .opisIskop {
    width: 22rem;
  }
}
@media (max-width: 410px) {
  .imgIskop {
    width: 17rem;
  }
  .opisIskop {
    width: 18rem;
  }
}
@media (max-width: 900px) {
}
@media (max-width: 900px) {
}
@media (max-width: 900px) {
}
