.footerInfo {
  color: white;
  gap: 2vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.socialIcons {
  width: 2rem;
  height: 2rem;
}
.footer {
  border-radius: 1vw;
  padding: 1rem;
  display: flex;
  /* margin: 4vw 10vw; */
  /* background: linear-gradient(to right, #fff9f9, #ff9d9d); */
  gap: 7rem;
}
.meniFoo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.meniFoo a {
  width: fit-content;
  text-decoration: none;
  color: white;
  transition: color 0.3s ease-in-out;
}
.meniFoo a:hover {
  color: red;
}
.kontaktFooter {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.email {
  width: fit-content;
  text-decoration: none;
  cursor: pointer;
  color: white;
  transition: color 0.4s ease-in-out;
}
.email:hover {
  color: red;
}
.lokacijaFooter {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.lokacijaFooter a {
  color: white;
  text-decoration: none;
  transition: color 0.4s ease-in-out;
  width: max-content;
}
.lokacijaFooter a:hover {
  color: red;
}
.social-icons {
  flex-flow: wrap-reverse;
  display: flex;
}
.kontaktFooter h1 {
  font-size: 1rem;
}
.logoo {
  width: 7rem;
}
.kontaktFooter p,
a {
  font-size: 0.75rem;
}
.footerImg {
  position: absolute;
  width: 100%;
  height: 33rem;
  z-index: -1;
  left: 0;
}
.footerDiv {
  width: -webkit-fill-available;
  place-content: center;
  padding-top: 17rem;
  display: flex;
  /* margin: 4vw 10vw; */
  /* background: linear-gradient(to right, #fff9f9, #ff9d9d); */
  gap: 7rem;
}
@media (max-width: 756px) {
  .footerDiv {
    gap: 0;
  }
}
@media (max-width: 518px) {
  .socialIcons {
    width: 1.5rem;
    height: 1.5rem;
  }
}

@media (max-width: 635px) {
  .footerInfo div h3 {
    font-size: 0.8rem;
  }
  .social-icons {
    align-self: flex-end;
  }
  .kontaktFooter p,
  a {
    font-size: 0.6rem;
  }
  .kontaktFooter {
    gap: 0.5rem;
  }
  .meniFoo {
    gap: 0.5rem;
  }
  .lokacijaFooter {
    gap: 0.5rem;
  }
}
@media (max-width: 495px) {
  .footerInfo {
    grid-template-columns: 1fr 1fr;
    padding-left: 13vw;
  }
}
@media (max-width: 1300px) {
}
@media (max-width: 1300px) {
}
@media (max-width: 1300px) {
}
