* {
  padding: 0;
  margin: 0;
}
.contact Img {
  width: -webkit-fill-available;
  filter: brightness(0.5);
}
.contactUs {
  color: white;
}
.imgContact {
  filter: brightness(0.5);
  height: 42rem;
  width: -webkit-fill-available;
  z-index: -1;
  position: absolute;
}
.kontaktDiv1 {
  justify-self: right;
  display: grid;
}
.kontaktDiv1 h2 {
  font-size: 1.1rem;
  text-transform: uppercase;
  padding-bottom: 1rem;
}
.contactDvaDiva {
  gap: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 3rem;
}
.input {
  width: 34.5rem;
  height: 2.5rem;
  border-radius: 0.3vw;
  border: none;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
}
textarea {
  padding-left: 0.5rem;
  border-radius: 0.3vw;
  padding-top: 0.5rem;
  width: 34.5rem;
  height: 8.5rem;
  margin-bottom: 1rem;
  border: none;
}
.posalji {
  cursor: pointer;
  text-align: center;
  background-color: #ff2b2b;
  width: 100%;
  display: inline-block;
  padding: 0.8rem 1rem;
  border: none;
  border-radius: 0.3vw;
  color: white;
  text-decoration: none;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: bold;
}
.posalji:hover {
  background-color: red;
}
.kontaktDiv2 {
  width: 34.5rem;
}
.kontaktDiv2 h2 {
  font-size: 1.1rem;
  text-transform: uppercase;
  padding-bottom: 1rem;
}
.vrijeme {
  display: flex;
  align-items: center;
  gap: 0.5vw;
}
.infoContact {
  justify-content: center;
  /* padding-bottom: 1vw; */
  padding: 2vw 0;
  display: grid;
  gap: 2rem;
  grid-template-columns: 23rem 23rem 23rem;
}
.info {
  border-radius: 0.5vw;
  padding: 2vw;
  background-color: #ff2b2b;
  /* height: 10vw; */
  width: 19rem;
  /* background-color: #c80b03; */
}

iframe {
  width: 34.5rem;
  height: 19.5rem;
  border: 0;
  border-radius: 0.5vw;
}
.ikoniceContact {
  width: 2vw;
  height: 2vw;
}
.info p {
  padding-top: 1vw;
}
.lokacija {
  text-decoration: none;
  color: white;
}
.loc {
  padding-bottom: 1vw;
}
.lokacija:hover {
  text-decoration: solid;
}
.support {
  gap: 1vw;
  width: 32vw;
  display: flex;
  padding-bottom: 1.8vw;
}
.support div {
  display: flex;
  flex-direction: column;
}
.zastoMi {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  padding: 5vw 10vw;
  gap: 10px;
}
.div1 {
  grid-column: span 2;
}
.ikoniceSupp {
  width: 3vw;
}
.kreiramo {
  color: #444;
  font-size: 2.8rem;
}
.unaprijedite {
  padding: 1vw 0 2vw 0;
  line-height: 1.5rem;
  color: #929292;
}
.support1 {
  height: 32vw;
  width: 34vw;
  border-radius: 0.5vw;
}

.imgSupport {
  position: relative;
}
.support2 {
  width: 21vw;
  height: 16vw;
  border-radius: 0.5vw;
  position: absolute;
  bottom: -5vw;
  left: -5vw;
}
.dvaDiva {
  position: relative;
}
.pZasto {
  color: #8b8b8b;
  font-weight: 600;
  padding-bottom: 1vw;
}
.support div h1 {
  padding-bottom: 0.3vw;
  font-size: 1.4vw;
  color: #444;
}
.support div p {
  line-height: 1.3rem;
  font-size: 0.9rem;
  color: #929292;
}
.strelicaPos {
  position: absolute;
  top: 1vw;
  left: -2vw;
  width: 5vw;
}

.prvi {
  justify-self: right;
}
.treci {
  justify-self: left;
}
.drugi {
  justify-self: center;
}
@media screen and (max-width: 1420px) {
  .zastoMi {
    padding: 12vw 10vw;
  }
}

@media (max-width: 1300px) {
  .input {
    width: 28.5rem;
  }
  textarea {
    width: 28.5rem;
  }
  iframe {
    width: 28.5rem;
  }
  .kontaktDiv2 {
    width: 28.5rem;
  }
  .infoContact {
    grid-template-columns: 19rem 19rem 19rem;
  }
  .info {
    width: 15.5rem;
  }
}
@media (max-width: 1046px) {
  .kontaktDiv1 h2 {
    font-size: 0.8rem;
    padding-bottom: 0.7rem;
  }
  .input {
    width: 22.5rem;
    font-size: 0.8rem;
    height: 2.1rem;
    justify-self: right;
  }
  textarea {
    width: 22.5rem;
    justify-self: right;
  }
  .kontaktDiv1 h2 {
    justify-self: right;
    width: 23rem;
    font-size: 0.8rem;

    padding-bottom: 0.7rem;
  }
  .kontaktDiv2 h2 {
    font-size: 0.8rem;
    padding-bottom: 0.7rem;
  }
  iframe {
    width: 22.5rem;
  }
  .kontaktDiv2 {
    width: 22.5rem;
  }
  .info {
    width: 12.5rem;
  }
  .info p {
    font-size: 0.9rem;
  }

  .infoContact {
    grid-template-columns: 15rem 15rem 15rem;
  }
  .ikoniceContact {
    width: 1.3rem;
    height: 1.3rem;
  }
  .vrijeme h3 {
    font-size: 0.9rem;
  }
}
@media (max-width: 830px) {
  iframe {
    height: 14rem;
    width: 34.5rem;
  }
  .contactDvaDiva {
    grid-template-columns: 1fr;
    padding-top: 2rem;
  }
  .input {
    width: 34.5rem;
  }
  textarea {
    width: 34.5rem;
  }
  .kontaktDiv1 {
    justify-self: center;
    display: grid;
  }
  .kontaktDiv1 h2 {
    justify-self: left;
  }
  .infoContact {
    grid-template-columns: 1fr;
  }
  .kontaktDiv2 {
    width: 34.5rem;
    justify-self: center;
  }

  .info {
    justify-self: center;
  }
  .info {
    height: 5rem;
    width: 15.5rem;
  }
}
@media (max-width: 615px) {
  .input {
    width: 25.5rem;
    justify-self: center;
    font-size: 0.7rem;
  }
  textarea {
    font-size: 0.7rem;
    height: 6.5rem;
    justify-self: center;
    width: 25.5rem;
  }
  iframe {
    width: 25.5rem;
    height: 14rem;
  }
  .kontaktDiv2 {
    width: 25.5rem;
  }
  .infoContact {
    padding-top: 4rem;
  }
}

@media (max-width: 452px) {
  .input {
    width: 17.5rem;
  }
  textarea {
    width: 17.5rem;
  }
  .infoContact {
    padding-top: 8rem;
  }
  .posalji {
    justify-self: center;
  }
  .kontaktDiv1 h2 {
    width: max-content;
    /* justify-self: left; */
  }
  .kontaktDiv2 {
    width: 18rem;
  }
  iframe {
    width: 18rem;
    height: 10rem;
  }

  .vrijeme h3 {
    font-size: 0.8rem;
  }
  .vrijeme {
    gap: 0.5rem;
  }
  .info {
    padding: 1rem;
  }
}

@media (max-width: 1260px) {
  .zastoMi {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Dva stupca */
    grid-template-rows: auto; /* Automatski redovi */
    gap: 10px; /* Razmak između divova */
  }

  .div1 {
    grid-column: span 2; /* Prvi div proteže se preko oba stupca */
  }
  .support1 {
    height: 25rem;
    width: 28rem;
    border-radius: 0.5vw;
  }
  .support2 {
    width: 16rem;
    height: 11rem;
    border-radius: 0.5vw;
    position: absolute;
    bottom: -2rem;
    left: -4rem;
  }
}

@media (max-width: 1160px) {
  .support1 {
    height: 21rem;
    width: 24rem;
    border-radius: 0.5vw;
  }
  .support2 {
    width: 11rem;
    height: 6rem;
    border-radius: 0.5vw;
    position: absolute;
    bottom: -2rem;
    left: -4rem;
  }
}

@media (max-width: 940px) {
  .support1 {
    height: 16rem;
    width: 20rem;
    border-radius: 0.5vw;
  }
  .support2 {
    width: 11rem;
    height: 6rem;
    border-radius: 0.5vw;
    position: absolute;
    bottom: -2rem;
    left: -2rem;
  }
  .support div p {
    line-height: 1rem;
    font-size: 0.75rem;
    color: #929292;
  }
  .kreiramo {
    font-size: 2.3rem;
  }
  .zastoMi {
    padding: 10rem 4rem 0 4rem;
  }
}
@media (max-width: 710px) {
  .support1 {
    height: 13rem;
    width: 17rem;
    border-radius: 0.5vw;
  }
  .zastoMi {
    padding: 5rem 2rem 0 2rem;
  }
  .support div h1 {
    padding-bottom: 0.3vw;
    font-size: 0.8rem;
    color: #444;
  }
  .kreiramo {
    font-size: 1.8rem;
  }
  .unaprijedite {
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
}
@media (max-width: 560px) {
  .support1 {
    height: 10rem;
    width: 14rem;
    border-radius: 0.5vw;
  }
  .kreiramo {
    font-size: 1.3rem;
  }
  .unaprijedite {
    font-size: 0.7rem;
    line-height: 1rem;
  }
  .support2 {
    width: 14rem;
    height: 6rem;
    border-radius: 0.5vw;
    position: absolute;
    bottom: -8rem;
    left: 0rem;
  }
  .pZasto {
    font-size: 0.7rem;
  }
}
@media (max-width: 400px) {
  .pZasto {
    font-size: 0.9rem;
  }
  .support div h1 {
    font-size: 0.9rem;
  }
  .ikoniceSupp {
    width: 1rem;
    padding-top: 0.1rem;
  }
}
@media (max-width: 345px) {
  .support1 {
    height: 6rem;
    width: 10rem;
    border-radius: 0.5vw;
  }
  .support2 {
    height: 4rem;
    bottom: -5rem;
    width: 10rem;
    border-radius: 0.5vw;
  }
}
@media (max-width: 464px) {
  .support div p {
    line-height: 0.7rem;
    font-size: 0.55rem;
    color: #929292;
  }
  .support1 {
    height: 7rem;
    width: 12rem;
    border-radius: 0.5vw;
  }
  .support2 {
    height: 5rem;
    bottom: -5rem;
    width: 12rem;
    border-radius: 0.5vw;
  }
}

@media (max-width: 464px) {
  .support1 {
    width: 10rem;
  }
  .support2 {
    width: 10rem;
  }
}
@media (max-width: 358px) {
  .support div h1 {
    font-size: 0.7rem;
  }
}
