.proizvodi {
  gap: 3rem;
  display: flex;
  padding: 4rem 7.7rem;
}
.podaciProizvoda {
  grid-column: span 4;
  display: flex;
  justify-content: space-between;
}
.proizvodSlike > div {
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.items {
  cursor: pointer;
  width: max-content;
  font-weight: 500;
  padding-left: 4px;
    transform: skew(-20deg);
    border-left: 4px solid #f85149;
}
.kategorije {
  display: flex;
  gap: 1vw;
  flex-direction: column;
}
.naslovProizvod {
  padding-bottom: 2vw;
}
.selectedP {
  font-size: 2rem;
  padding-bottom: 3vw;
  font-weight: bolder;
}
.selected {
  border-bottom: 2px solid;
  color: red;
  border-color: red;
}
.proizvodSlike {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.podaciProizvoda{
  display: flex;
  justify-content: space-between;
}
.naslovProizvoda{
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  color: #242424;
  padding-bottom: 7px;
}
.cijenaProizvoda{
  align-items: center;
  height: 1.5rem;
  transform: skew(-20deg);
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
  color: #f85149;
  padding-left: 7px;
  border-left: 7px solid #f85149;
}
.filterImg {
  width: 16vw;
  height: 18vw;
  object-fit: cover;
  border-radius: 0.5vw;
}
@media (max-width: 1029px) {
  .proizvodi {
    gap: 2rem;
    padding: 4rem 4rem;
  }
}
@media (max-width: 800px) {

.proizvodSlike > div {
  grid-template-columns:  1fr 1fr 1fr;
}
.podaciProizvoda {
  grid-column: span 3;}
  .proizvodi {
    gap: 1rem;
    padding: 4rem 2rem;
  }
  .proizvodSlike {

  }
  .filterImg {
    width: 21vw;
    height: 27vw;
    border-radius: 0.5vw;
  }
}

@media (max-width: 750px) {
  .proizvodi {
    gap: 1rem;
    padding: 11rem 2rem;
  }
}
@media (max-width: 600px) {
  .items {
    font-size: 0.9rem;
    cursor: pointer;
    width: max-content;
  }
  .selectedP {
    font-size: 1.5rem;
  }
  .proizvodSlike {
  
  }
  .filterImg {
    width: 28vw;
    height: 35vw;
  }
}
@media (max-width: 500px) {
  .kategorije {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    gap: 10px;
    
    width: 100%;
}
.filterImg {
  width: 100%;
  height: 12rem;
}
  .proizvodi {
    flex-direction: column;
    gap: 1rem;
    padding: 11rem 2rem;
  }
  .naslovProizvod {
    grid-column: span 2;
  }
}
@media (max-width: 668px) {

.proizvodSlike > div {
  grid-template-columns:  1fr 1fr;
}
.podaciProizvoda {
  grid-column: span 2;}
  .proizvodi {
    gap: 1rem;
    padding: 2rem 2rem;
  }
}
@media (max-width: 388px) {
  .kategorije {
    grid-template-columns: 1fr 1fr !important;
  }
  .naslovProizvod {
    grid-column: span 2;
  }
}
@media (max-width: 394px) {
  .footerDiv {
    flex-direction: column;
  }
}
@media (max-width: 668px) {
}
