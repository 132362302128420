@media (max-width: 1097px) {
  .projektiPage .opisNaSlici {
    top: 8rem;
  }
}
@media (max-width: 915px) {
  .projektiPage .opisNaSlici h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 800px) {
  .projektiPage .opisNaSlici {
    left: 3rem;
  }
}
@media (max-width: 824px) {
  .projektiPage .opisNaSlici {
    width: 32rem;

    top: 8rem !important;
  }
}
@media (max-width: 1294px) {
  .projektiPage .opisNaSlici {
    width: 79vw !important;
    .opisNaSlici h1 {
      font-size: 5vw;
    }
  }
}
@media (max-width: 1104px) {
  .projektiPage .opisNaSlici {
    top: 8rem;
  }
  .projektiPage .opisNaSlici h1 {
    font-size: 5vw;
  }
}
@media (max-width: 600px) {
  .projektiPage .opisNaSlici h1 {
    font-size: 2rem;
  }
  .projektiPage .opisNaSlici h1 {
    font-size: 2rem;
  }

  .projektiPage .opisNaSlici {
    width: 27rem;
  }
}
@media (max-width: 530px) {
  .projektiPage .navbarLink {
    width: 25rem;
  }
}
@media (max-width: 490px) {
  .projektiPage .navbarLink {
    width: 22rem;
  }
}
@media (max-width: 434px) {
  .projektiPage .navbarLink {
    width: 19rem;
  }
  .projektiPage .logo {
    padding-left: 0vw;
    width: 10rem;
    height: 3rem;
  }
}
@media (max-width: 386px) {
  .projektiPage .logo {
    padding-left: 0vw;
    width: 8rem;
    height: 2.5rem;
  }
  .projektiPage .navbarLink {
    width: 18rem;
  }
}
@media (max-width: 370px) {
  .projektiPage .navbarLink {
    width: 17rem;
  }
}
@media (max-width: 344px) {
  .projektiPage .navbarLink {
    width: 16rem;
  }
}
@media (max-width: 444px) {
  .projektiPage .opisNaSlici h1 {
    font-size: 1.5rem;
  }
  .projektiPage .opisNaSlici p {
    font-size: 0.8rem;
    line-height: 1rem;
    padding-top: 0.5rem;
  }
  .projektiPage .opisNaSlici {
    width: 23rem;
  }
}
@media (max-width: 400px) {
  .projektiPage .opisNaSlici h1 {
    font-size: 1.5rem;
  }
  .projektiPage .opisNaSlici p {
    font-size: 0.8rem;
    line-height: 1rem;
    padding-top: 0.5rem;
  }
  .projektiPage .opisNaSlici {
    width: 20rem;
  }
  .projektiPage .opisNaSlici {
    top: 6rem !important;
  }
}
@media (max-width: 355px) {
  .projektiPage .opisNaSlici h1 {
    font-size: 1.2rem;
  }
  .projektiPage .opisNaSlici p {
    font-size: 0.7rem;
    line-height: 1rem;
    padding-top: 0.5rem;
  }
  .projektiPage .opisNaSlici {
    width: 17rem;
  }
  .projektiPage .opisNaSlici {
    top: 7rem !important;
  }
}
