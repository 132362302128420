.gotoviProjektiMainImg {
    width: 100%;
border-radius:7px;
    object-fit: cover;
    height: 471px;
}
.gotoviProjektiName{
    font-size: 1.7rem;
    color: #292929;
    text-align: center;
    padding-bottom: 7px;
}
.gotoviProjektiDiv{
    padding-top: 80px;
    display: grid;
    grid-template-columns: 1000px;

    place-items: center;
}
.gotoviProjektiContainer{
    display: grid;
    place-items: center;
    background: linear-gradient(#fff2f2, #ffffff);
}


.slider {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .slideWrapper {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .slide {
    min-width: 100%;
    display: none;
    
  }
  
  .slide.active {
    display: inline;
  }
  
  .prevBtn, .nextBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    font-size: 38px;
    color: #fff;
    cursor: pointer;
}
  
  .prevBtn {
    left: 7px;
  }
  .gotoviProjektiHeader {
    display: grid;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    grid-template-columns: 1fr 2fr 1fr;
}
  
  .nextBtn {
    right:7px;
  }
  .gotoviProjektiA{
    font-size: 1rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: #292929;

}
.gotoviProjektiA img{
    width: 33px;
    height: 26px;
}
  .gotoviProjektiTekst{
    font-size: .98rem;
    font-weight: 600;
    color: #292929;
    padding-top: 1rem;
}
.gotoviProjektiContainer .header {

    height: auto;
}
.gotoviProjektiContainer .bgImg{    display: none;}
.gotoviProjektiContainer .navbarLink {
     position: relative;}
     @media (max-width: 1056px) {
        .gotoviProjektiDiv {
          grid-template-columns: 859px;
        }
      }
      
      @media (max-width: 956px) {
        .gotoviProjektiDiv {
          grid-template-columns: 759px;
        }
      }
      
      @media (max-width: 856px) {
        .gotoviProjektiDiv {
          grid-template-columns: 659px;
        }
      }
      
      @media (max-width: 756px) {
        .gotoviProjektiDiv {
          grid-template-columns: 559px;
        }
        .gotoviProjektiMainImg {
            height: 337px;
        }
      }
      
      @media (max-width: 683px) {
        .gotoviProjektiName{
            transform: skew(-20deg);
        }
        .gotoviProjektiContainer{
            overflow-x: hidden;
        }
        .gotoviProjektiDiv {
          grid-template-columns: 459px;
        }
        .gotoviProjektiName {
            font-size: 1rem;}
        .gotoviProjektiA {
            font-size: .8rem;}
      }
      
      @media (max-width: 556px) {
        .gotoviProjektiDiv {
          grid-template-columns: 459px;
        }
      }
      
      @media (max-width: 495px) {
        .gotoviProjektiDiv {
          grid-template-columns: 359px;
        }
        .gotoviProjektiMainImg {
            height: 273px;
        }
      }
      
      @media (max-width: 495px) {
      .gotoviProjektiDiv {
        grid-template-columns: 1fr;
        margin: 0 1rem;
    }}