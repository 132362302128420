body {
  font-family: sans-serif !important;
  margin: 0;
  padding: 0;
}

.usluge {
  padding: 5vw 13vw;
  color: #474747;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4vw;
}

.primjerSlika {
  border-radius: 0.5vw;
  width: 37rem;
}
.viseOnama {
  font-size: 0.9vw;
  border-bottom: 0.14vw solid red;
  text-decoration: none;
  color: #474747;
  font-weight: 700;
}
.usluge2 h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  padding-bottom: 1rem;
}
.usluge1 h1 {
  padding-bottom: 1rem;
  font-size: 2.5rem;
}
.usluge2 {
  padding-top: 5vw;
  width: 31rem;
}
.usluge2 p {
  padding-bottom: 1rem;
  color: #959595;
  line-height: 1.5rem;
}
.strelica {
  width: 1.5vw;
}
.ownImg {
  width: 31rem;
  border-radius: 0.5vw;
  margin-top: 2rem;
}
.ikonice {
  padding-right: 2vw;
  width: 4vw;
  height: 4vw;
  align-self: center;
}
.divPodaci {
  display: flex;
}
.statistika {
  display: grid;
  padding: 4vw 13vw;
  place-content: center;
  gap: 6vw;
  grid-template-columns: 1fr 1fr 1fr;
}
.statsPodaci {
  height: 6vw;
  border-left: 0.15vw solid #969696;
  padding-left: 2vw;
}
.statsPodaci h1 {
  padding-bottom: 0.5vw;
  padding-top: 0.5vw;
  height: 3vw;
  font-size: 3vw;
  margin: 0;
  color: #ff0000ba;
}
.statsPodaci p {
  /* height: 0.5vw; */
  font-size: 0.88vw;
  font-weight: 600;
  color: #898989;
}
.inView {
  display: grid;
}
.usluge1,
.usluge2 {
  opacity: 0;
}

.inView .usluge2 {
  animation: premaDesno 1.8s forwards;
}

.inView .usluge1 {
  animation: premaLijevo 1.8s forwards;
}

@keyframes premaDesno {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes premaLijevo {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.imgPavers {
  width: -webkit-fill-available;
  height: 40vw;
  filter: brightness(0.55);
}
.pavers {
  position: relative;
}
.textPavers {
  color: white;
  position: absolute;
  top: 12vw;
  text-align: -webkit-center;
  width: 40vw;
  left: 30vw;
}
.textPavers h1 {
  font-size: 3vw;
  text-shadow: 2px 2px 4px #0000009e;
}
.textPavers a {
  text-decoration: none;
  color: white;
  background-color: red;
  padding: 0.9vw 2vw;
  border-radius: 1.5vw;
  font-size: 1vw;
  font-weight: 600;
}
.textPavers p {
  padding-top: 2vw;
  padding-bottom: 3vw;
  line-height: 1.5vw;
  color: #eaeaea;
  font-size: 1vw;
}
.uskaSlika {

  border-radius: 0.5vw;
  margin-top: 0.8rem;
}
.kontaktiraj:hover {
  background-color: rgba(255, 0, 0, 0.875);
}
.divUsluga {
  border-radius: 0.5vw;
  box-shadow: 0px 0px 4px 3px #d8d8d89e;
  position: relative;
  flex-direction: column;
  display: flex;
}
.triDiva {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 13vw 5vw 13vw;
  gap: 2vw;
}
.pogledajDetalje {
  background-color: #ff0000c4;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.7rem 0.8rem;
  color: white;
  text-decoration: none;
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: bold;
  position: absolute;
  right: 0;
  border-top-right-radius: 0.5vw;
  border-bottom-left-radius: 0.5vw;
}
.ikoniceUsluge {
  width: 4.4rem;
  padding: 1rem 0 0 1rem;
}
.usluzneInfo {
  padding-left: 1rem;
  font-size: 1rem;
}

.usluzniOpis {
  padding-left: 1rem;
  padding-bottom: 1rem;
  line-height: 1.35rem;
  color: #9e9e9e;
  padding-right: 1rem;
  padding-top: 1rem;
}
.naslovTriDiva {
  font-size: 2.5rem;
  padding: 5vw 13vw 5vw 13vw;
  line-height: 3.8rem;
}
.pogledajDetalje:hover {
  background-color: #62aaff;
}
@media (max-width: 1450px) {
  .usluge {
    padding: 5vw 9vw;
  }
}
@media (max-width: 1350px) {
  .primjerSlika {
    width: 32rem;
  }
  .uskaSlika {
    width: 32rem;

    height: 16rem;
  }
  .usluge {
    gap: 1.5vw;
  }
}
@media (max-width: 1191px) {
  .primjerSlika {
    width: 26rem;
  }
  .uskaSlika {
    width: 26rem;

    height: 16rem;
  }
  .usluge {
    gap: 1.5rem;
  }
}
@media (max-width: 1051px) {
  .ownImg {
    height: 20rem;
  }
  .primjerSlika {
    width: 22rem;
  }
  .uskaSlika {
    width: 22rem;

    height: 16rem;
  }
  .usluge {
    gap: 1.5rem;
  }
  .usluge1 h1 {
    font-size: 2rem;
  }
}
@media (max-width: 979px) {
  .usluge {
    padding: 5vw 12vw;
  }
  .usluge {
    grid-template-columns: 22rem 22rem;
  }
  .ownImg {
    height: 15rem;
  }
  .usluge2 {
    width: 22rem;
  }
  .ownImg {
    width: 22rem;
  }
  .uskaSlika {
    height: 13rem;
  }
  .usluge1 {
    width: 22rem;
  }
  .viseOnama {
    font-size: 0.8rem;
  }
}
@media (max-width: 850px) {
  .usluge {
    grid-template-columns: 1fr;
  }
  .ownImg {
    height: 24rem;
  }
  .usluge2 {
    width: 37rem;
  }
  .ownImg {
    width: 70vw;
  }
  .usluge1 h1 {
    width: 37rem;
  }
  .uskaSlika {
    height: 20rem;
    width: 37rem;
  }
  .primjerSlika {
    width: 70vw;
  }
  .usluge1 {
    width: 37rem;
  }
  .viseOnama {
    font-size: 0.8rem;
  }
}
@media (max-width: 750px) {
  .ownImg {
    height: 21rem;
  }
  .usluge2 {
    width: 33rem;
  }
  .ownImg {
  }
  .usluge1 h1 {
    width: 33rem;
  }
  .uskaSlika {
    height: 17rem;
    width: 70vw;
  }

  .usluge1 {
    width: 33rem;
  }
  .viseOnama {
    font-size: 0.7rem;
  }
}
@media (max-width: 650px) {
  .ownImg {
    height: 18rem;
  }
  .usluge2 {
    width: 30rem;
  }
  .ownImg {
  }
  .usluge1 h1 {
    width: 30rem;
  }
  .uskaSlika {
    height: 17rem;
  }

  .usluge1 {
    width: 30rem;
  }
  .viseOnama {
    font-size: 0.7rem;
  }
}
@media (max-width: 580px) {
  .ownImg {
    height: 15rem;
  }
  .usluge2 {
    width: 27rem;
  }
  .usluge1 h1 {
    font-size: 2rem;
  }
  .usluge2 h2 {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .usluge2 p {
    font-size: 0.8rem;
    line-height: 1.1rem;
  }
  .ownImg {
  }
  .usluge1 h1 {
    width: 27rem;
  }
  .uskaSlika {
    height: 14rem;
  }

  .usluge1 {
    width: 27rem;
  }
  .viseOnama {
    font-size: 0.6rem;
  }
  .ownImg {
    margin-top: 1.5rem;
  }
}
@media (max-width: 516px) {
  .ownImg {
    height: 12rem;
    margin-top: 1rem;
  }
  .usluge2 {
    width: 23rem;
  }
  .usluge1 h1 {
    font-size: 2rem;
  }
  .usluge2 h2 {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  .usluge2 p {
    font-size: 0.8rem;
    line-height: 1.1rem;
  }
  .ownImg {
  }
  .usluge1 h1 {
    width: 23rem;
  }
  .uskaSlika {
    height: 14rem;
  }

  .usluge1 {
    width: 23rem;
  }
  .viseOnama {
    font-size: 0.6rem;
  }
  .usluge2 p {
    padding-bottom: 0.5rem;
  }
}
@media (max-width: 640px) {
  .textPavers {
    width: -webkit-fill-available;
    left: 0;
    padding: 0rem 5rem;
    color: white;
    /* position: absolute; */
    top: 5rem;
    text-align: -webkit-center;
    /* width: 26rem; */
    /* left: 7rem; */
  }
  .textPavers h1 {
    font-size: 2rem;
    text-shadow: 2px 2px 4px #0000009e;
  }
  .textPavers p {
    padding-top: 2vw;
    padding-bottom: 3vw;
    line-height: 1.2rem;
    color: #eaeaea;
    font-size: 0.8rem;
  }
  .textPavers a {
    text-decoration: none;
    color: white;
    background-color: red;
    padding: 0.7rem 1.5rem;
    border-radius: 1.5vw;
    font-size: 1rem;
    font-weight: 600;
  }
  .imgPavers {
    width: -webkit-fill-available;
    height: 22rem;
    filter: brightness(0.55);
  }
  .ikonice {
    padding-right: 2vw;
    width: 1.5rem;
    height: 1.5rem;
    align-self: center;
  }
  .statistika {
    display: grid;
    padding: 4vw 5vw;
    place-content: center;
    gap: 0vw;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .statsPodaci h1 {
    font-size: 1rem;
    margin: 0;
    color: #ff0000ba;
  }
  .statsPodaci p {
    /* height: 0.5vw; */
    font-size: 0.4rem;
    font-weight: 600;
    color: #898989;
    padding-top: 0.5rem;
  }
  .divPodaci {
    height: 2rem;
  }
}
@media (max-width: 440px) {
  .textPavers h1 {
    font-size: 1.5rem;
    text-shadow: 2px 2px 4px #0000009e;
  }
  .textPavers {
    width: -webkit-fill-available;
    left: 0;
    padding: 0rem 3rem;
    color: white;
    /* position: absolute; */
    top: 5rem;
    text-align: -webkit-center;
    /* width: 26rem; */
    /* left: 7rem; */
  }
  .textPavers p {
    padding-top: 1rem;
    padding-bottom: 2rem;
    line-height: 1.2rem;
    color: #eaeaea;
    font-size: 0.9rem;
  }
  .statistika {
    display: grid;
    padding: 4vw 3vw;
    place-content: center;
    gap: 0vw;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .statsPodaci h1 {
    font-size: 1rem;
    margin: 0;
    color: #ff0000ba;
  }
  .usluge2 {
    width: 17rem;
  }
  .ikonice {
    padding-right: 2vw;
    width: 1.5rem;
    height: 1.5rem;
    align-self: center;
  }
  .usluge1 h1 {
    font-size: 2rem;
  }
  .usluge2 h2 {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }
  .statsPodaci p {
    /* height: 0.5vw; */
    font-size: 0.4rem;
    font-weight: 600;
    color: #898989;
    padding-top: 0.5rem;
  }
  .usluge2 p {
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .usluge2 p {
    padding-bottom: 0.2rem;
  }
  .divPodaci {
    height: 2rem;
  }
  .ownImg {
    height: 12rem;
    margin-top: 0.5rem;
  }
  .usluge1 h1 {
    width: 17rem;
  }
  .uskaSlika {
    height: 8rem;
  }

  .usluge1 {
    width: 17rem;
  }
  .viseOnama {
    font-size: 0.7rem;
  }
}
@media (max-width: 1379px) {
  .naslovTriDiva {
    line-height: 3rem;
  }
}

@media (max-width: 1075px) {
  .naslovTriDiva {
    line-height: 2.3rem;
    font-size: 2rem;
  }
}
@media (max-width: 900px) {
  .triDiva {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  .naslovTriDiva {
    line-height: 2rem;
    font-size: 1.7rem;
  }
}
@media (max-width: 584px) {
  .naslovTriDiva {
    line-height: 1.5rem;
    font-size: 1.2rem;
  }
  .pogledajDetalje {
    padding: 0.5rem 0.6rem;

    font-size: 0.6rem;
  }
  .ikoniceUsluge {
    width: 3.4rem;
  }
  .usluzniOpis {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .usluzneInfo {
    font-size: 0.9rem;
  }
}
