* {
  margin: 0;
  padding: 0;
}

.projektiSlike {
  width: 23vw;
  border-top-right-radius: 1vw;
  border-top-left-radius: 1vw;
  object-fit: cover;
  height: 15vw;
}
.projektiKartice {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5vw;
}
.zavrseniProjekti {
  padding: 3vw 10vw;
}
.projekatDiv {
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.2);
  box-shadow: black 1 1 1;
  width: 23vw;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  position: relative;
}
.projekatDiv p {
  padding: 0vw 1rem;
}
.seeMoreProjekat {
  background-color: red;
  padding: 1vw;
  width: max-content;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom-right-radius: 1vw;
  border-top-left-radius: 1vw;
  margin-top: 2vw;
}
.projektiTekst {
  color: #797979;
  padding-bottom: 4vw !important;
  line-height: 1.3rem;
  border-radius: 1vw;
}
.projektiNaslov {
  color: #4a4a4a;
  text-transform: uppercase;
  font-weight: 600;

  font-size: 1.2rem;
}
.naslovZavrsenihProjekata {
  padding-top: 5vw;
  padding-left: 10vw;
  color: #4a4a4a;
  font-size: 1.7rem;
}
.filterImg {
  width: 20vw;
  height: 20vw;
}
.modalImage {
  width: 30rem;
  height: 30rem;
}
@media (max-width: 1000px) {
  .projektiTekst {
    padding-bottom: 6vw !important;
  }
  .seeMoreProjekat {
    padding: 1.4vw;
  }
  .projektiKartice {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5vw;
  }
  .projekatDiv {
    width: 33vw;
  }
  .projektiSlike {
    width: 33vw;
  }
  .zavrseniProjekti {
    padding: 3vw 15vw;
  }
  .naslovZavrsenihProjekata {
    padding-left: 15vw;
    padding-right: 15vw;
  }
}
@media (max-width: 750px) {
  .naslovZavrsenihProjekata {
    padding-top: 22vw;
  }
}
@media (max-width: 669px) {
  .naslovZavrsenihProjekata {
    padding-top: 6vw;
  }
}
@media (max-width: 600px) {
  .projektiTekst {
    font-size: 1.1rem;
  }
  .projektiKartice {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4vw;
  }
  .projekatDiv {
    width: 70vw;
  }
  .projektiSlike {
    width: 70vw;
    height: 33vw;
  }
  .projektiTekst {
    padding-bottom: 3.5rem !important;
  }
  .seeMoreProjekat {
    background-color: red;
    font-size: 0.8rem;
    padding: 0.8rem 1.4rem;
  }
}
@media (max-width: 1070px) {
}
@media (max-width: 1070px) {
}
