.oNama .opisNaSlici {
  top: 21vw;
}
.team {
  width: 30vw;
}
.teamContainer {
  padding: 5vw 12vw;
  display: flex;
}
.tekstOpis p {
  color: #888;
  padding-top: 1rem;
  line-height: 1.4rem;
}
.naslovOnama {
  color: #515151;
}
