body {
  font-family: sans-serif !important;
  margin: 0;
  padding: 0;
}
.navbar a {
  transition: color 0.3s ease-in-out;
  width: 4rem;
  font-size: 0.9rem;
  text-decoration: none;
  color: #474747;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.navbar a:hover {
  color: red;
}
.logo {
  padding-left: 2vw;
  width: 15rem;
  height: 3.5rem;
}
.header {
  position: relative;
  height: 50vw;
}
.navbar {
  gap: 3.5vw;
  align-self: center;
  display: flex;
  text-transform: uppercase;
}
.naslovna {
  width: -webkit-fill-available;
  height: 50vw;
  filter: brightness(0.55);
}
.navbarLink {
  display: flex;
  position: absolute;
  top: 1.5vw;
  background-color: white;
  gap: 14vw;
  height: 5.5rem;
  margin: 0px 12vw;
  border-radius: 0.5vw;
}
.navbar a:last-child {
  display: flex;
  border-top-right-radius: 0.5vw;
  border-bottom-right-radius: 0.5vw;
  color: white;
  height: 5.5rem;
  background-color: red;
  padding: 0 4vw;
  align-items: center;
}
.navbar {
  align-items: center;
  gap: 2.5vw;
  align-self: center;

  display: flex;
}
.navbar a:last-child:hover {
  background-color: rgb(255, 73, 73);
}
.opisNaSlici {
  color: white;
  width: 40rem;
  position: absolute;
  top: 15rem;
  left: 10rem;
  height: 30rem;
}

.opisNaSlici h1 {
  font-size: 4rem;
}

.opisNaSlici h1,
.opisNaSlici p,
.opisNaSlici a {
  opacity: 0;
}

.opisNaSlici h1 {
  animation: slideInFromBottom 1.8s forwards;
  padding-bottom: 1vw;
}

.opisNaSlici p,
.opisNaSlici a {
  animation: slideInFromLeft 1.8s forwards;
}

@keyframes slideInFromBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.opisNaSlici p {
  padding-bottom: 3rem;
  font-size: 1.2rem;
  line-height: 1.8vw;
}
.kontaktiraj {
  text-decoration: none;
  color: white;
  background-color: red;
  padding: 1.2rem;
  font-size: 1rem;
  font-weight: 600;
  margin-right: 2vw;
}
.uslugeBtn {
  border: 0.15vw solid;
  text-decoration: none;
  color: white;

  padding: 1.2rem;

  font-size: 1rem;
  height: 1vw;
  font-weight: 600;
}
.logoDiv {
  align-self: center;
}
.kontaktirajNas {
  cursor: pointer;
  height: 5.5rem !important;
}
@media (max-width: 863px) {
  .opisNaSlici {
  }
}

@media (max-width: 750px) {
  .opisNaSlici {
    top: 15vw !important;
  }
  .naslovna {
    height: 65vw;
    padding-bottom: 1rem;
  }
  .usluge {
    margin-top: 7rem;
  }
}

@media (min-width: 877px) {
  .dropMenu {
    display: none !important;
  }
}

@media (max-width: 877px) {
  .navbar {
    display: none;
  }

  .meni {
    display: flex !important;
  }
  .navbarLink {
    width: 77vw;

    justify-content: space-between;
    padding: 0 2rem;
  }
  .meni {
    display: none;
    width: 2.5rem;
    height: 2.5rem;
    align-self: center;
  }
}
@media (max-width: 1325px) {
  .navbarLink {
    margin: 0px 9vw;
  }
}

@media (max-width: 1177px) {
  .navbarLink {
    gap: 5vw;
  }
}

@media (max-width: 1090px) {
  .navbarLink {
    margin: 0px 6vw;
  }
}
@media (max-width: 983px) {
  .navbar a {
    width: 3rem;
    font-size: 0.7rem;
  }
  .navbarLink {
    height: 4.5rem;
  }
  .navbar a:last-child {
    height: 4.5rem;
  }
  .navbarLink {
    margin: 0px 9vw;
  }
}
@media (max-width: 803px) {
  .navbarLink {
    margin: 0px 6vw;
  }
}
.meni {
  display: none;
}
@media (max-width: 729px) {
  .navbarLink {
    width: 77vw;
  }
}
@media (max-width: 680px) {
  .navbarLink {
  }

  .navbarLink {
    width: 77vw;
  }

  .navbarLink {
    margin: 0px 8vw;
  }
}
@media (max-width: 619px) {
  .navbarLink {
    margin: 0px 9vw;
  }

  .navbarLink {
    width: 77vw;
  }
}
@media (max-width: 571px) {
  .navbarLink {
  }
  .logo {
    padding-left: 2vw;
    width: 12rem;
    height: 3rem;
  }
  .meni {
    display: none;
    width: 2rem;
    height: 2rem;
    align-self: center;
  }
  .navbarLink {
    padding: 0 1rem;
  }
}
@media (max-width: 337px) {
  .naslovna {
    height: 93vw !important;
  }
}
@media (max-width: 503px) {
  .navbarLink {
    width: 77vw;
  }
  .opisNaSlici {
    left: 2.6rem !important;
  }
}

@media (max-width: 468px) {
  .logo {
  }
  .navbarLink {
    width: 77vw;
  }
}

@media (max-width: 400px) {
  .navbarLink {
  }
  .navbarLink {
    width: 77vw;
  }
}
@media (max-width: 366px) {
  .navbarLink {
    width: 77vw;
  }
}
@media (max-width: 338px) {
  .navbarLink {
    width: 77vw;
  }
}

@media (max-width: 1200px) {
  .opisNaSlici {
    color: white;
    width: 31rem;
    position: absolute;
    top: 12rem;
    left: 8rem;
    height: 30rem;
  }
  .opisNaSlici h1 {
  }
  .opisNaSlici p {
    padding-bottom: 2rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .kontaktiraj {
    text-decoration: none;
    color: white;
    background-color: red;
    padding: 1rem;
    border-radius: 0.5vw;
    font-size: 1rem;
    font-weight: 600;
    margin-right: 2rem;
  }
  .uslugeBtn {
    border: 0.15vw solid;
    text-decoration: none;
    color: white;
    padding: 1rem;
    border-radius: 0.5vw;
    font-size: 1rem;
    height: 1rem;
    font-weight: 600;
  }
}

@media (max-width: 1040px) {
  .opisNaSlici {
    color: white;
    width: 27rem;
    position: absolute;
    top: 9rem;
    left: 5rem;
    height: 25rem;
  }
  .opisNaSlici h1 {
  }
  .opisNaSlici p {
    padding-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 834px) {
  .opisNaSlici h1 {
    font-size: 3rem;
  }
  .opisNaSlici {
    width: 25rem;
    height: max-content;
    top: 7rem;
  }
  .opisNaSlici p {
  }
  .kontaktiraj {
    padding: 0.8rem;
    font-size: 0.8rem;
  }
  .uslugeBtn {
    padding: 0.8rem;
    font-size: 0.8rem;
  }
}
@media (max-width: 668px) {
  .usluge {
    margin-top: 0;
  }
  .opisNaSlici h1 {
    font-size: 2.8rem;
  }
  .opisNaSlici p {
    font-size: 0.89rem;
    line-height: 1.4rem;
    padding-bottom: 2rem;
  }
  .kontaktiraj {
    padding: 1rem;
    font-size: 0.7rem;
  }
  .uslugeBtn {
    padding: 1rem;
    font-size: 0.7rem;
  }
  .naslovna {
    height: 70vw;
    padding-bottom: 1rem;
  }
  .header {
    height: auto;
  }
  .opisNaSlici {
    width: 23rem;

    top: 6rem;
  }
}
@media (max-width: 490px) {
  .opisNaSlici h1 {
    font-size: 1.8rem;
  }
  .naslovna {
    height: 78vw;
    padding-bottom: 1rem;
  }
}
@media (max-width: 570px) {
  .opisNaSlici h1 {
    font-size: 2.2rem;
  }
  .opisNaSlici p {
  }
  .kontaktiraj {
    padding: 1rem;
    font-size: 0.7rem;
  }
  .uslugeBtn {
    padding: 1rem;
    font-size: 0.7rem;
  }

  .opisNaSlici {
    width: 23rem;
    left: 3.3rem;
    top: 4.5rem;
  }
}

@media (max-width: 422px) {
  .naslovna {
    height: 22rem !important;
  }
  .navbarLink {
    height: 4rem;
  }
}
.kontaktiraj {
  border-radius: 3vw;
}
.uslugeBtn {
  border-radius: 3vw;
}
@media (max-width: 462px) {
  .opisNaSlici p {
    font-size: 0.9rem;
    line-height: 1.2rem;
    padding-bottom: 2rem;
  }
  .opisNaSlici h1 {
    font-size: 1.7rem;
  }
  .kontaktiraj {
    padding: 1rem;
    font-size: 0.7rem;
  }
  .opisNaSlici {
    width: 16.5rem;
    left: 3rem;
    top: 4rem;
  }

  .uslugeBtn {
    padding: 1rem;
    font-size: 0.7rem;
  }
}

@media (max-width: 370px) {
  .opisNaSlici p {
  }
  .kontaktiraj {
  }
  .uslugeBtn {
  }

  .opisNaSlici {
  }
}
@media (min-width: 1500px) {
  .opisNaSlici {
    left: 14rem;
  }
}

.dropMenu {
  padding-top: 1rem;
  display: none;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  overflow: hidden;
  position: fixed;
  left: 0rem;
  text-align: center;
  top: 4rem;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 5;
}
.dropMenu a {
  transition: color 0.3s ease-in-out;
  width: 4rem;
  font-size: 0.9rem;
  text-decoration: none;
  color: #474747;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.open {
  border-bottom-left-radius: 1vw;
  border-bottom-right-radius: 1vw;
  display: flex;
  background-color: #ffc3c3;
  transform: translateX(0);
  flex-direction: column;
  width: -webkit-fill-available;

  position: absolute;
  text-align: left;
  padding-left: 2rem;
  height: 14.5rem;
}
.dropMenu a:hover {
  color: red;
}
.logoMeni {
  width: 9rem;
  height: 2.5rem;
}
.medjuMeni {
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 6rem 2rem;
  text-align: justify;
  background-color: white;
  width: 41rem;
  height: inherit;
  right: 0;
}
.closeMeni {
  right: 14rem;
  top: 2rem;
  position: absolute;
  /* rotate: none; */
  width: 3rem;
  height: 3rem;
  transform: rotate(180deg);
}
@media (max-width: 1324px) {
  .opisNaSlici {
    top: 12vw;
    left: 7rem;
  }
}
@media (max-width: 1086px) {
  .opisNaSlici {
    top: 12vw;
    left: 4rem;
  }
}
@media (max-width: 1041px) {
  .opisNaSlici {
    width: 59vw;
    top: 12vw;
    left: 4rem;
  }
}
@media (max-width: 966px) {
  .opisNaSlici h1 {
    font-size: 2.2rem;
  }
  .opisNaSlici {
  
  }
}
@media (max-width: 632px) {
}
@media (max-width: 523px) {
  .naslovna {
    height: 86vw;
  }
  .opisNaSlici {
    width: 75vw;
    top: 23vw !important;
    left: 3rem;
  }
}
@media (max-width: 450px) {
  .opisNaSlici h1 {
    font-size: 2rem;
  }
}
@media (max-width: 396px) {
  .opisNaSlici {
    top: 5.5rem !important;
  }
}
@media (max-width: 1324px) {
}
@media (max-width: 1324px) {
}
@media (max-width: 1324px) {
}
@media (max-width: 1324px) {
}
